export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pleaseUploadPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload photos with the following information"])},
        "pleaseUploadInspectionPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload photo(s)"])},
        "ownerIdCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner ID card / Passport and Work permit"])},
        "carRegistrationBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car registration book"])},
        "currentPolicySchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current policy schedule"])},
        "drivingLicenseOfTheMainDriver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving license of the main driver and/or additional drivers"])},
        "noClaimBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Claim Bonus (NCB)"])},
        "validateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only jpeg, png, heif or heic (for iPhone), 20 MB max size for each, 10 images only"])},
        "addPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add photo"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
        "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload error"])},
        "saveDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "documentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Type"])},
        "policyDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Document"])},
        "inspecitonPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Inspection"])},
        "bookBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proof of income"])}
      },
      "th": {
        "pleaseUploadPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาอัปโหลดรูปภาพเอกสารต่อไปนี้"])},
        "pleaseUploadInspectionPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาอัปโหลดรูปภาพ"])},
        "ownerIdCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัตรประชาชนเจ้าของรถ / พาสปอร์ตและหนังสือรับรองการทำงาน"])},
        "carRegistrationBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คู่มือจดทะเบียนรถ"])},
        "currentPolicySchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตารางกรมธรรม์ปัจจุบัน"])},
        "drivingLicenseOfTheMainDriver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบขับขี่ของผู้ขับขี่หลัก / ใบขับขี่ผู้ขับขี่เพิ่มเติม"])},
        "noClaimBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หนังสือรับรองส่วนลดประวัติดี NCB"])},
        "validateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉพาะไฟล์ jpeg, png, heif หรือ heic (สำหรับ iPhone), ขนาดไม่เกิน 20 MB, จำนวนไม่เกิน 10 รูป"])},
        "addPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มรูป"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปโหลด"])},
        "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปโหลด ไม่สำเร็จ"])},
        "saveDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก"])},
        "documentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทเอกสาร"])},
        "policyDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอกสารกรมธรรม์"])},
        "inspecitonPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสภาพ"])},
        "bookBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอกสารแสดงรายได้"])}
      },
      "zh": {
        "pleaseUploadPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请上传带有以下信息的照片"])},
        "pleaseUploadInspectionPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload photo(s)"])},
        "ownerIdCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车主身份证/护照和工作证"])},
        "carRegistrationBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汽车登记簿"])},
        "currentPolicySchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现有保单附表"])},
        "drivingLicenseOfTheMainDriver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要司机和/或其他司机的驾驶执照"])},
        "noClaimBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无索赔折扣 (NCB)"])},
        "validateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅限jpeg、png、heif或heic(适用于iPhone)，每张照片最大尺寸为20MB，仅10张照片。"])},
        "addPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加照片"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传"])},
        "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传错误"])},
        "saveDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "documentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Type"])},
        "policyDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Document"])},
        "inspecitonPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Inspection"])},
        "bookBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proof of income"])}
      }
    }
  })
}
