<template>
    <div class="monitor" style="color:#ffffff" v-html="result">
    </div>
  </template>
  <script>  
  import axios from "axios";
  import Global from "@/stores/GlobalVariables.js";
  export default {    
    name: "Monitor",
    data: function () {
        return {
            result: ""
        }
    },
    async created(){
        try {
            let response = await axios.get(Global.apiUrl + "/monitor");
            if(response){
                this.result = response.data;
            }
        } catch (error) {
            this.result = "";
        }
    }
  }
  </script>
  