export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "inspectionPhotoNotfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos upload by Intermediary not found"])}
      },
      "th": {
        "inspectionPhotoNotfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos upload by Intermediary not found"])}
      },
      "zh": {
        "inspectionPhotoNotfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos upload by Intermediary not found"])}
      }
    }
  })
}
