export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "document.label.will.generated.shortly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The E-documents are in process and will be available soon."])},
        "doc.tran.compulsory.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory insurance policy schedule"])},
        "doc.tran.welcome.letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome letter"])},
        "doc.tran.voluntary.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voluntary insurance policy schedule"])},
        "doc.tran.addendum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addendum"])},
        "doc.tran.voluntary.tax.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voluntary insurance tax invoice"])},
        "doc.tran.compulsory.tax.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory insurance tax invoice"])},
        "doc.tran.rsa.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadside assistance service contract schedule"])},
        "doc.tran.rsa.tax.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadside assistance service receipt/tax invoice"])},
        "doc.tran.ci.addendum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car inspection addendum"])},
        "doc.tran.tc.instalment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions for instalment payment"])},
        "doc.tran.voluntary.endorsement.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voluntary endorsement note"])},
        "doc.tran.voluntary.credit.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voluntary credit note"])},
        "doc.tran.voluntary.debit.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voluntary debit note"])},
        "doc.tran.compulsory.endorsement.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory endorsement note"])},
        "doc.tran.compulsory.credit.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory credit note"])},
        "doc.tran.compulsory.debit.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory debit note"])},
        "doc.tran.ew.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECEIPT / TAX INVOICE"])},
        "doc.tran.ew.rsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadside Assistance Service Contract Schedule"])}
      },
      "th": {
        "document.label.will.generated.shortly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบกำลังเตรียมเอกสารอิเล็กทรอนิกส์ และจะสามารถดาวน์โหลดได้ในเร็ว ๆ นี้"])},
        "doc.tran.compulsory.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตารางกรมธรรม์ประกันรถภาคบังคับ (พ.ร.บ.)"])},
        "doc.tran.welcome.letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จดหมายต้อนรับ"])},
        "doc.tran.voluntary.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตารางกรมธรรม์ประกันรถภาคสมัครใจ"])},
        "doc.tran.addendum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกเพิ่มเติม"])},
        "doc.tran.voluntary.tax.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบกำกับภาษีประกันรถภาคสมัครใจ"])},
        "doc.tran.compulsory.tax.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบกำกับภาษีประกันรถภาคบังคับ (พ.ร.บ.)"])},
        "doc.tran.rsa.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัญญาบริการช่วยเหลือฉุกเฉินบนท้องถนน"])},
        "doc.tran.rsa.tax.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบเสร็จรับเงิน / ใบกำกับภาษีบริการช่วยเหลือฉุกเฉินบนท้องถนน"])},
        "doc.tran.ci.addendum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกเพิ่มเติมตรวจสภาพรถ"])},
        "doc.tran.tc.instalment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อกำหนดและเงื่อนไขการผ่อนชำระ"])},
        "doc.tran.voluntary.endorsement.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอกสารแนบท้ายกรมธรรม์ประกันภัยภาคสมัครใจ"])},
        "doc.tran.voluntary.credit.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบลดหนี้ประกันภัยภาคสมัครใจ"])},
        "doc.tran.voluntary.debit.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบเพิ่มหนี้ประกันภัยภาคสมัครใจ"])},
        "doc.tran.compulsory.endorsement.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอกสารแนบท้ายกรมธรรม์ประกันภัยภาคบังคับ (พ.ร.บ.)"])},
        "doc.tran.compulsory.credit.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบลดหนี้ประกันภัยภาคบังคับ (พ.ร.บ.)"])},
        "doc.tran.compulsory.debit.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มหนี้ประกันภัยภาคบังคับ (พ.ร.บ.)"])},
        "doc.tran.ew.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบเสร็จรับเงิน / ใบกำกับภาษี"])},
        "doc.tran.ew.rsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตารางสรุปสัญญาบริการช่วยเหลือบนท้องถนน"])}
      },
      "zh": {
        "document.label.will.generated.shortly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在为您准备电子保单文件中。约2小时后，您可重新回到这里下载。"])},
        "doc.tran.compulsory.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory insurance policy schedule"])},
        "doc.tran.welcome.letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome letter"])},
        "doc.tran.voluntary.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voluntary insurance policy schedule"])},
        "doc.tran.addendum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addendum"])},
        "doc.tran.voluntary.tax.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voluntary insurance tax invoice"])},
        "doc.tran.compulsory.tax.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory insurance tax invoice"])},
        "doc.tran.rsa.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadside assistance service contract schedule"])},
        "doc.tran.rsa.tax.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadside assistance service receipt/tax invoice"])},
        "doc.tran.ci.addendum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car inspection addendum"])},
        "doc.tran.tc.instalment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions for instalment payment"])},
        "doc.tran.voluntary.endorsement.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voluntary endorsement note"])},
        "doc.tran.voluntary.credit.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voluntary credit note"])},
        "doc.tran.voluntary.debit.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voluntary debit note"])},
        "doc.tran.compulsory.endorsement.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory endorsement note"])},
        "doc.tran.compulsory.credit.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory credit note"])},
        "doc.tran.compulsory.debit.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory debit note"])},
        "doc.tran.ew.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECEIPT / TAX INVOICE"])},
        "doc.tran.ew.rsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadside Assistance Service Contract Schedule"])}
      }
    }
  })
}
