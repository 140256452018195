<style>
    #upload-photos-ocr .btn-outline-primary, #upload-photos-ocr .btn-outline-primary:active:focus, #upload-photos-ocr .btn-outline-primary:focus {
        color: #003b71;
        border: 2px solid #003b71 !important;
    }
    #upload-photos-ocr .btn-outline-primary{
        background-color: #ffffff;
        border: 2px solid #003b71;
    }
    #upload-photos-ocr .btn-outline-primary:hover{
        color: #ffffff;
        background-color: #0158a7;
        border: 2px solid #003b71;
    }
    #upload-photos-ocr .btn-start-upload{
        color: #fff;
        background-color: #ff5011 !important;
        border-color: #f74100 !important;
    }
    #upload-photos-ocr .btn-start-upload svg{
        vertical-align: middle;
    }
    #upload-photos-ocr .btn-start-upload:hover{
        color: #fff;
        background-color: #ff5011 !important;
        border-color: #f74100 !important;
    }
    #upload-photos-ocr .list-group-img {
        max-width: 500px;
        margin: 0 auto;
        display: flex;
        flex-direction: column !important;
        padding-left: 0 !important;
    }
    #upload-photos-ocr .list-group-item {
        padding: 1rem .5rem;
        border-color: rgb(236 239 241);
    }
    #upload-photos-ocr .list-group-item:first-child {
        border: none;
    }
    #upload-photos-ocr .list-group-item:last-child {
        border-bottom: none;
    }
    #upload-photos-ocr .list-group-item .img-size{
        color: #a4b0be;
    }
    #upload-photos-ocr .list-group-item .progress {
        background-color: #ffffff;
    }
    #upload-photos-ocr .list-group-item .progress-bar {
        background-color: #4cd137;
    }
    #upload-photos-ocr .list-group-item .img-size>strong {
        font-weight: 400;
    }

    #upload-photos-ocr .list-group-item .media .preview-img{
        width:20%;
    }

    #upload-photos-ocr .list-group-item .media .preview-name{
        width:80%;
    }

    #upload-photos-ocr .btn-next {
        color: #fff;
        background-color: #ff5011 !important;
        border-color: #f74100 !important;
    }
    #upload-photos-ocr .btn-remove-img:hover {
        background-color: #ffd1c0;
    }
    #upload-photos-ocr .list-group-item.dz-success .img-tools .btn-remove-img, #upload-photos-ocr .list-group-item .img-tools .check-uploaded, #upload-photos-ocr .list-group-item.dz-error .img-tools .check-uploaded{
        display: none;
    }
    #upload-photos-ocr .list-group-item.dz-success .img-tools .check-uploaded{
        display: block;
    }
    /* #upload-photos-ocr .list-group-item .media-body , #upload-photos-ocr .list-group-item .media-body .img-detail {
        overflow: hidden;
    } */

    #upload-photos-ocr .media-body .row {
        display:flex;
        text-align: left !important;
    }

    #upload-photos-ocr .d-none {
        display: none;
    }

    #upload-photos-ocr svg {
        overflow: hidden !important;
        vertical-align: middle !important;
    }

    #upload-photos-ocr .text-center {
        text-align: center!important;
    }

    #upload-photos-ocr .justify-content-center {
        -ms-flex-pack: center!important;
        justify-content: center!important;
    }

    #upload-photos-ocr .media {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    #upload-photos-ocr .validateMessage {
        color: #ee9800;
    }

    #upload-photos-ocr .btn-remove-img, #upload-photos-ocr .btn-remove-img:hover{
        box-shadow: unset !important;
        background-color: transparent;
    }
</style>

<template>
    <div id="upload-document">
        <div class="input-group mb-4" v-if="reportName !== 'inspectionReport'  &&  reportName !== 'activepolicyReport' && !isNullOrEmpty(documentType)">
          <label class="input-group-text" for="product-dropdown">{{$t("documentType")}}</label>
          <select
            class="form-select"
            id="product-dropdown"
            v-model="selectedDocType"
          >
            <option
              v-for="doc in documentType"
              v-bind:value="doc"
              :key="doc"
            >
              {{ $t(doc) }}
            </option>
          </select>
        </div>
        <div id="upload-photos-ocr" class="text-center">
            <div class="text-default">{{selectedDocType === 'policyDocument' ? $t("pleaseUploadPhotos") : $t('pleaseUploadInspectionPhotos')}}</div>
            <div id="intro-upload" class="">
                <svg width="75" height="75" viewBox="0 0 16 16" class="bi bi-images my-4" fill="#ced6e0" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M12.002 4h-10a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1zm-10-1a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-10zm4 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                    <path fill-rule="evenodd" d="M4 2h10a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1v1a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2h1a1 1 0 0 1 1-1z"/>
                </svg>
                <div class="mx-auto" style="max-width: 300px; font-size: .875rem;" v-if="selectedDocType === 'policyDocument'">
                    <ul class="text-left pl-2" v-if="this.productType != 'PAComboSave'">
                        <li>{{$t("ownerIdCard")}}</li>
                        <li>{{$t("carRegistrationBook")}}</li>
                        <li>{{$t("currentPolicySchedule")}}</li>
                        <li>{{$t("drivingLicenseOfTheMainDriver")}}</li>
                        <li>{{$t("noClaimBonus")}}</li>
                    </ul>
                    <ul class="text-left pl-2" v-else>
                        <li>{{$t("bookBank")}}</li>
                    </ul>
                </div>
            </div>
            <ul id="previews" class="list-group list-group-flush list-group-img mt-4 files">
            </ul>
            <div id="actions" class="row justify-content-center text-center px-4">
                <div class="col-10 col-lg-4 my-2 col-add">
                    <button  id="btn-AddDocument" data-selenium-name="add-document" type="button" class="btn btn-primary btn-sm btn-block fileinput-button bg-gradient-info">
                        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>{{$t("addPhoto")}}
                    </button>
                </div>
                <div class="col-10 col-lg-4 my-2 col-start d-none">
                    <button id="btn-UploadDocument" data-selenium-name="upload-document" type="submit" class="btn btn-primary btn-sm btn-block btn-start-upload start bg-gradient-primary">
                        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg> {{$t("upload")}}
                    </button>
                </div>
                <div class="col-xs-12 col-md-12">
                    <p class="my-3 validateMessage" id="validateMessage" style="font-size: .875rem;">
                         {{$t("validateMessage")}}
                    </p>
                </div>
            </div>
            <ul id="previews-template" class="list-group list-group-flush list-group-img mt-4 files d-none">
                <li class="list-group-item file-row">
                    <div class="media">
                        <div class="preview-img"><span class="preview"><img data-dz-thumbnail class="rounded mr-3" /></span></div>
                        <div class="media-body preview-name text-left">
                            <div class="row">
                                <div class="col-xs-10 col-lg-10 img-detail">
                                    <div class="ml-md-2">
                                        <p class="img-name my-1" data-dz-name>Name</p>
                                        <p class="img-size small mb-1" data-dz-size>Size</p>
                                        <p class="error text-danger small m-0" data-dz-errormessage></p>
                                    </div>
                                </div>
                                <div class=" col-xs-2 col-lg-2 img-tools text-center">
                                    <button class="btn btn-sm text-danger btn-remove-img cancel" title="Remove" data-dz-remove><svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button>

                                    <span class="check-uploaded p-2"><svg viewBox="0 0 24 24" width="20" height="20" stroke="#4cd137" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="20 6 9 17 4 12"></polyline></svg></span>
                                </div>
                            </div>
                            <div class="progress progress-striped active mt-1" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0"  style="height: 2px;">
                                <div class="progress-bar progress-bar-success" style="width:0%;" data-dz-uploadprogress></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import axios from "axios";
import mixin from "@/components/Mixin";
import Dropzone from "../../public/js/dropzone.min.js"

export default {
    mixins: [mixin],
    name: 'UploadDocument',
    data() {
        return {
            Global,
            dmsToken: null,
            myDropzone: null,
            ocrToken: null,
            documentType: [], 
            selectedDocType: "policyDocument",
            arrFiles: {}
        }
    },
    props: {
        reportName: String,
        productType: String,
        oppName: String,
        oppId: String,
        vid_sfid: String,
        vid_status: String,
        plan_type: String
    },
    created() {
        if(this.reportName === "inspectionReport"){
            this.selectedDocType = "inspecitonPhoto";
        }else if(this.reportName === "opportunityReport"){
            this.documentType.push("policyDocument");
            if(this.vid_status === 'Open' && this.plan_type !== '1'){
                this.documentType.push("inspecitonPhoto");
            }
        }else if(this.reportName === "activepolicyReport"){
            this.documentType.push("policyDocument");
        }
    },
    methods: {
        initDropZone: function(){
            var vm = this;
            document.querySelector("#previews").innerHTML = "";
            var previewNode = document.querySelector("#previews-template");
            var previewTemplate = previewNode.innerHTML;
            var introUpload = document.querySelector("#intro-upload");
            var previewUl = document.querySelector("#previews");
            var addBtn = document.querySelector(".col-add");
            var startBtn = document.querySelector(".col-start");
            var uploadMaxFiles = 10;
            if(!vm.myDropzone){
                vm.myDropzone = new Dropzone("div#upload-document", { // Make the whole body a dropzone
                    url: Global.uploadDocument.url + "/update/addFile/" + vm.sfid, // Set the url
                    maxFilesize: 20, // MB
                    maxFiles: uploadMaxFiles,
                    acceptedFiles: "image/jpeg, image/png, image/jpg, .heif, .heic", //".png, .jpg, .jpeg, .heif, .heic", //.JPEG , .PNG , HEIF (for iPhone) ,HEIC (for iPhone)
                    thumbnailWidth: 65,
                    thumbnailHeight: 65,
                    parallelUploads: 20,
                    previewTemplate: previewTemplate,
                    autoQueue: false, // Make sure the files aren't queued until manually added
                    previewsContainer: "#previews", // Define the container to display the previews
                    clickable: ".fileinput-button", // Define the element that should be used as click trigger to select files.
                    renameFile: function (file) {
                        let newName = new Date().getTime() + "_" + file.name.replace(/\s+/g, '_');
                        return newName;
                    },
                    headers: {
                        Authorization: "Bearer " + vm.dmsToken
                    },
                });

                vm.myDropzone.on("addedfile", function() {
                    if (previewUl.children.length !== 0) {
                        introUpload.classList.add("d-none");
                        startBtn.classList.remove("d-none");
                    }

                    if(previewUl.children.length >= uploadMaxFiles){
                        addBtn.classList.add("d-none");
                    }
                });

                vm.myDropzone.on("removedfile", function() {
                    if (previewUl.children.length == 0) {
                        introUpload.classList.remove("d-none");
                        startBtn.classList.add("d-none");
                    }

                    if(previewUl.children.length < uploadMaxFiles){
                        addBtn.classList.remove("d-none");
                    }

                });

                vm.myDropzone.on("sending", function () {
                    document.querySelector(".col-upload button")
                });

                vm.myDropzone.on("success", function (file, resp) {
                    if (resp.error != undefined && resp.error.length > 0){
                        console.log(resp.error);
                    } else {
                        if( resp.name ){
                            try {
                                 vm.arrFiles[resp.name] = file;
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    }
                });

                vm.myDropzone.on("error", function (file, errorMessage) {
                    vm.topLoading("hide");
                    if(errorMessage.msg !== "access denied"){
                        vm.myDropzone.removeFile(file);
                    }else{
                        vm.getDmsToken();
                        document.querySelector(".dz-error .error.text-danger").text(vm.$t("uploadError"));
                    }
                });

                document.querySelector("#actions .start").onclick = function() {
                    vm.getDmsToken(async function(){
                        vm.ocrToken = await vm.getTokenNjsmcs();
                        vm.myDropzone.enqueueFiles(vm.myDropzone.getFilesWithStatus(Dropzone.ADDED));
                    });
                };

                document.querySelector(".start").addEventListener('click', function() {
                    startBtn.classList.add("d-none");
                });

                vm.myDropzone.on("queuecomplete", function () {
                    var uploadedPhotos = document.querySelectorAll(".dz-success").length;
                    var errorPhotos = document.querySelectorAll(".dz-error").length;
                    if ((uploadedPhotos === 0 || errorPhotos > 0) && Global.isRetrieve !== true) {
                        Global.uploadDocument.isError = true;
                    }else{
                        Global.uploadDocument.isError = false;
                        vm.tagApi(vm.arrFiles);
                    }
                });
            }else{
                vm.myDropzone.options.url = Global.uploadDocument.url + "/update/addFile/" + vm.sfid;
            }
        },
        getDmsToken: function(callback){
            var vm = this;
            try {
                axios.post(Global.uploadDocument.url + "/authenticate", {
                    username: Global.uploadDocument.authenUsername,
                    password: Global.uploadDocument.authPassword
                },
                {
                    headers: {
                    "Content-Type": "application/json"}
                }).then((response) => {
                    vm.dmsToken = response.data.token;
                    vm.myDropzone.options.headers = {
                        Authorization: "Bearer " + vm.dmsToken
                    };

                    if (callback && typeof(callback) === "function") {
                        callback();
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        ocrDetection: async function(file){
            var vm = this;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('opportunityNumber', vm.oppName);
            formData.append('opportunityId', vm.oppId);

            return await axios.post(Global.njsmcsURL + "/ocr/textDetection", formData,
                {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Bearer " + vm.ocrToken
                    }
                })
                .then((response) => {
                    return response.data;
                }, (response) => {
                    console.log(response);
                });
        },
        tagApi: async function(tagFiles){
            let vm = this;
            let tagObj = {};
            for(let property in tagFiles){
                tagObj[property] = [vm.getGATag(await vm.ocrDetection(tagFiles[property]))];
            }
            if(Object.keys(tagObj).length > 0){
                let req = {
                    'prefix':  vm.sfid,
                    'tags': tagObj
                };
                await axios.post(Global.uploadDocument.url + "/update/bulkUpdateObjectTags", req, {
                    headers: {"Authorization": "Bearer " + vm.dmsToken}
                }).catch(function (error) {
                    console.log("addTag error", error);
                });
            }

        },
        getGATag: function(ocrResponse){
            if(ocrResponse && ocrResponse.tag != 'Uploaded by Customer'){
                return ocrResponse.tag + " by Intermediary";
            }else {
                return "Upload by Intermediary";
            }
        }
    },
    mounted() {
        var vm = this;
        vm.initDropZone();
    },
    computed: {
        sfid: function(){
            if(this.selectedDocType === "policyDocument"){
                return this.oppId ? this.oppId : this.oppName
            }else if(this.selectedDocType === "inspecitonPhoto"){
                return this.vid_sfid;
            }else{
                return this.oppName;
            }
        }
    },
    watch: {
        selectedDocType: function(){
            var vm = this;
            setTimeout(function(){
                vm.initDropZone();
            }, 150);
        }
    },
    beforeUnmount(){
        document.querySelector("#previews").innerHTML = "";
    }
}
</script>

<i18n>
{
  "en": {
    "pleaseUploadPhotos": "Please upload photos with the following information",
    "pleaseUploadInspectionPhotos": "Please upload photo(s)",
    "ownerIdCard": "Owner ID card / Passport and Work permit",
    "carRegistrationBook": "Car registration book",
    "currentPolicySchedule": "Current policy schedule",
    "drivingLicenseOfTheMainDriver": "Driving license of the main driver and/or additional drivers",
    "noClaimBonus": "No Claim Bonus (NCB)",
    "validateMessage": "Only jpeg, png, heif or heic (for iPhone), 20 MB max size for each, 10 images only",
    "addPhoto": "Add photo",
    "upload": "Upload",
    "uploadError": "Upload error",
    "saveDocument": "Save",
    "documentType": "Document Type",
    "policyDocument": "Policy Document",
    "inspecitonPhoto": "Photo Inspection",
    "bookBank": "Proof of income"
  },
  "th": {
    "pleaseUploadPhotos": "กรุณาอัปโหลดรูปภาพเอกสารต่อไปนี้",
    "pleaseUploadInspectionPhotos": "กรุณาอัปโหลดรูปภาพ",
    "ownerIdCard": "บัตรประชาชนเจ้าของรถ / พาสปอร์ตและหนังสือรับรองการทำงาน",
    "carRegistrationBook": "คู่มือจดทะเบียนรถ",
    "currentPolicySchedule": "ตารางกรมธรรม์ปัจจุบัน",
    "drivingLicenseOfTheMainDriver": "ใบขับขี่ของผู้ขับขี่หลัก / ใบขับขี่ผู้ขับขี่เพิ่มเติม",
    "noClaimBonus": "หนังสือรับรองส่วนลดประวัติดี NCB",
    "validateMessage": "เฉพาะไฟล์ jpeg, png, heif หรือ heic (สำหรับ iPhone), ขนาดไม่เกิน 20 MB, จำนวนไม่เกิน 10 รูป",
    "addPhoto": "เพิ่มรูป",
    "upload": "อัปโหลด",
    "uploadError": "อัปโหลด ไม่สำเร็จ",
    "saveDocument": "บันทึก",
    "documentType": "ประเภทเอกสาร",
    "policyDocument": "เอกสารกรมธรรม์",
    "inspecitonPhoto": "ตรวจสภาพ",
    "bookBank": "เอกสารแสดงรายได้"
  },
  "zh": {
    "pleaseUploadPhotos": "请上传带有以下信息的照片",
    "pleaseUploadInspectionPhotos": "Please upload photo(s)",
    "ownerIdCard": "车主身份证/护照和工作证",
    "carRegistrationBook": "汽车登记簿",
    "currentPolicySchedule": "现有保单附表",
    "drivingLicenseOfTheMainDriver": "主要司机和/或其他司机的驾驶执照",
    "noClaimBonus": "无索赔折扣 (NCB)",
    "validateMessage": "仅限jpeg、png、heif或heic(适用于iPhone)，每张照片最大尺寸为20MB，仅10张照片。",
    "addPhoto": "添加照片",
    "upload": "上传",
    "uploadError": "上传错误",
    "saveDocument": "Save",
    "documentType": "Document Type",
    "policyDocument": "Policy Document",
    "inspecitonPhoto": "Photo Inspection",
    "bookBank": "Proof of income"
  }
}
</i18n>
