export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "detailHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunity Detail"])},
        "oppNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunity Number"])},
        "productOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Option"])},
        "excess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excess"])},
        "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
        "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver"])},
        "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory"])},
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
        "activateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated Date"])},
        "closedWonDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed Won Date"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "brokerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broker Name"])},
        "vehicleInspection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Inspection"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
          "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Required"])}
        },
        "agentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent email"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
        "subModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Model"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of manufacture"])},
        "carPlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Plate"])},
        "carPlateProvince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Information"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "custMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
        "custEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "retrieveQuoteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieve Quote Link"])},
        "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click"])},
        "mrKumka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr.Kumka"])},
        "businessValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business validate"])},
        "notPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Pass"])},
        "fullPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full"])},
        "12monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 months installment"])},
        "10monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 months installment with deposit"])},
        "10monthsInstallmentNoDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 months installment"])},
        "4monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 months installment"])},
        "premiumInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Information"])},
        "transactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction date"])},
        "transactionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction type"])},
        "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Plan"])},
        "netPremiumIncludeVatAndStamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Premium (Including VAT & Stamp)"])},
        "grossPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Premium"])},
        "uploadDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload document"])},
        "downloadPolicyDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Documents"])},
        "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
        "displayVehicleInspecitonPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Inspection Photos"])}
      },
      "th": {
        "detailHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดใบเสนอราคา"])},
        "oppNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขใบเสนอราคา"])},
        "productOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลือกผลิตภัณฑ์"])},
        "excess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าเสียหายส่วนแรก"])},
        "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อู่"])},
        "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ขับขี่"])},
        "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ.ร.บ"])},
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้าง"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเริ่มกรมธรรม์"])},
        "activateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ปิดการขาย"])},
        "closedWonDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ปิดการขาย"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะใบเสนอ"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะกรมธรรม์"])},
        "brokerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โบรกเกอร์"])},
        "vehicleInspection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสภาพรถ"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต้องตรวจสภาพรถ"])},
          "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ต้องตรวจสภาพรถ"])}
        },
        "agentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล์ที่ปรึกษา"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลิตภัณฑ์"])},
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยี่ห้อ"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รุ่น"])},
        "subModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดรุ่น"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปีที่ผลิต"])},
        "carPlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้ายทะเบียน"])},
        "carPlateProvince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้ายทะเบียนรถ จังหวัด"])},
        "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดข้อมูลลูกค้า"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อลูกค้า"])},
        "custMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์โทรศัพท์"])},
        "custEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล์"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์"])},
        "retrieveQuoteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูใบเสนอราคา"])},
        "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิก"])},
        "mrKumka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr.Kumka"])},
        "businessValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรับประกัน"])},
        "notPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ผ่าน"])},
        "fullPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จ่ายเต็มจำนวน"])},
        "12monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่อน 12 งวด"])},
        "10monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่อน 10 งวด (มีมัดจำ)"])},
        "10monthsInstallmentNoDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่อน 10 งวด"])},
        "4monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่อน 4 งวด"])},
        "premiumInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเบี้ยประกัน"])},
        "transactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ทำรายการ"])},
        "transactionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทการทำรายการ"])},
        "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แผนการชำระเงิน"])},
        "netPremiumIncludeVatAndStamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบี้ยประกันภัยสุทธิ (รวมภาษีมูลค่าเพิ่มและตราประทับ)"])},
        "grossPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบี้ยประกันภัยรวม"])},
        "uploadDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัพโหลดเอกสาร"])},
        "downloadPolicyDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดาวน์โหลดเอกสาร"])},
        "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกลิงค์"])},
        "displayVehicleInspecitonPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Inspection Photos"])}
      },
      "zh": {
        "detailHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机会详情"])},
        "oppNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机会号码"])},
        "productOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Option"])},
        "excess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超额部分"])},
        "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["维修店铺"])},
        "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["司机"])},
        "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强制性的"])},
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始日期"])},
        "activateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated Date"])},
        "closedWonDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed Won Date"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "brokerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经纪人姓名"])},
        "vehicleInspection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆检验"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要"])},
          "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不需要"])}
        },
        "agentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理邮箱"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["型号"])},
        "subModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子型号"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产年份"])},
        "carPlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车牌"])},
        "carPlateProvince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户资料"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "custMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户手机号码"])},
        "custEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户邮箱"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接"])},
        "retrieveQuoteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检索报价链接"])},
        "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击"])},
        "mrKumka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr.Kumka"])},
        "businessValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商业验证"])},
        "notPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不通过"])},
        "fullPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full"])},
        "12monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 months installment"])},
        "10monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 months installment with deposit"])},
        "10monthsInstallmentNoDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 months installment"])},
        "4monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 months installment"])},
        "premiumInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Information"])},
        "transactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction date"])},
        "transactionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction type"])},
        "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Plan"])},
        "netPremiumIncludeVatAndStamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Premium (Including VAT & Stamp)"])},
        "grossPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Premium"])},
        "uploadDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload document"])},
        "downloadPolicyDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Documents"])},
        "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
        "displayVehicleInspecitonPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Inspection Photos"])}
      }
    }
  })
}
