
<style>
 #download-document .download-list-item {
  border: none;
  color: #ff5011;
}
</style>

<template>
<div id="download-document">
    <div v-if="policyDocuments.length>0">
        <div v-for="(policy, policyIndex) in policyDocuments" :key="policyIndex">
            <span class="ms-1 font-weight-bold">{{policy.quoteName}}</span>
            <a v-for="(currentDocument, currentDocumentIndex) in policy.documents" 
                :key="currentDocumentIndex" 
                href="javascript:void(0)" 
                class="download-list-item list-group-item list-group-item-action"
                @click="download(currentDocument, policy.bucketName)" 
                :id="'btn-' + currentDocumentIndex"
                data-selenium-name="document-list" 
                :data-selenium-value="currentDocument.name"
            >
            <div>
                <i class="fas fa-download"></i> {{ doctranslateNewApp( currentDocument.name ) }}
            </div>
            </a> 
        </div>
    </div>
    <div v-else style="color: #ff5011">
        {{ $t("document.label.will.generated.shortly") }}
    </div>
</div>
</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";
import axios from "axios";

export default {
    mixins: [mixin],
    name: 'DownloadDocument',
    data() {
        return {
            Global
        }
    },
    props: {
        policyDocuments: Array
    },
       methods: {
        doctranslateNewApp:function(doc){
            if( !doc ){
            return doc;
            }

            var vm = this, name = doc.toLocaleLowerCase().trim();

            if (name.includes('compulsory policy schedule')) {
                return vm.$t('doc.tran.compulsory.schedule');
            } else if (name.includes('renewal welcome letter') || name.includes('welcome letter')) {
                return vm.$t('doc.tran.welcome.letter');
            } else if (name.includes('voluntary policy schedule')) {
                return vm.$t('doc.tran.voluntary.schedule');
            } else if (name.includes('addendum') && !name.includes('car inspection addendum')) {
                return vm.$t('doc.tran.addendum');
            } else if (name.includes('voluntary tax invoice')) {
                return vm.$t('doc.tran.voluntary.tax.invoice');
            } else if (name.includes('compulsory tax invoice')) {
                return vm.$t('doc.tran.compulsory.tax.invoice');
            } else if (name.includes('roadside assistance service contract schedule')) {
                return vm.$t('doc.tran.rsa.schedule');
            } else if (name.includes('roadside assistance service receipt / tax invoice')) {
                return vm.$t('doc.tran.rsa.tax.invoice');
            } else if (name.includes('car inspection addendum')) {
                return vm.$t('doc.tran.ci.addendum');
            } else if (name.includes('t & c for instalment payment') || name.includes('t&c for instalment payment')) {
                return vm.$t('doc.tran.tc.instalment');
            } else if (name.includes('voluntary endorsement note')) {
                return vm.$t('doc.tran.voluntary.endorsement.note');
            } else if (name.includes('voluntary credit note')) {
                return vm.$t('doc.tran.voluntary.credit.note');
            } else if (name.includes('voluntary debit note')){
                return vm.$t('doc.tran.voluntary.debit.note');
            } else if (name.includes('compulsory endorsement note')) {
                return vm.$t('doc.tran.compulsory.endorsement.note');
            } else if (name.includes('compulsory credit note')) {
                return vm.$t('doc.tran.compulsory.credit.note');
            } else if (name.includes('compulsory debit note')) {
                return vm.$t('doc.tran.compulsory.debit.note');
            } else if (name.includes('rj product tax invoice')) {
                return vm.$t('doc.tran.ew.invoice');
            } else if (name.includes('rsa contract schedule')) {
                return vm.$t('doc.tran.ew.rsa');
            } else {
                return doc
            }
        },
        download: async function(document, bucketName){
            var vm = this;
            if(bucketName !== null && bucketName !== undefined){
                if(Global.myAccount.token === ""){
                    try{
                        var myAccountToken = await this.getMyAccountToken();
                        Global.myAccount.token = myAccountToken.data.access_token;
                    }catch (error){
                        console.error(error);
                    }
                }
                if(Global.myAccount.token !== ""){
                    await vm.downloadFileNewApp('/customer/downloadNewDoc', bucketName, document.path, document.name);
                }
            }
        },
        downloadFileNewApp: function(endpoint, bucketName, path, name){
            var vm = this;
            vm.topLoading("show");
            axios.get(Global.myAccount.url + endpoint, {
                params: {
                bucketName : bucketName,
                documentPath : path,
                documentName : name,
                isNew : true
                },
                headers: {"Authorization": "Bearer " + Global.myAccount.token}
            })
            .then(response => {
                if (response.data.result) {
                    var byteCharacters = atob(response.data.model);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL, '_blank', 'location=no,top=0,left=0,fullscreen=yes');
                }
                vm.topLoading("hide");
            })
            .catch(error => {
                console.log(error)
                vm.topLoading("hide");
            });
        }
    }
}
</script>

<i18n>
    {
        "en": {
            "document.label.will.generated.shortly":  "The E-documents are in process and will be available soon.",
            "doc.tran.compulsory.schedule": "Compulsory insurance policy schedule",
            "doc.tran.welcome.letter": "Welcome letter",
            "doc.tran.voluntary.schedule": "Voluntary insurance policy schedule",
            "doc.tran.addendum": "Addendum",
            "doc.tran.voluntary.tax.invoice": "Voluntary insurance tax invoice",
            "doc.tran.compulsory.tax.invoice": "Compulsory insurance tax invoice",
            "doc.tran.rsa.schedule": "Roadside assistance service contract schedule",
            "doc.tran.rsa.tax.invoice": "Roadside assistance service receipt/tax invoice",
            "doc.tran.ci.addendum": "Car inspection addendum",
            "doc.tran.tc.instalment": "Terms and conditions for instalment payment",
            "doc.tran.voluntary.endorsement.note": "Voluntary endorsement note",
            "doc.tran.voluntary.credit.note": "Voluntary credit note",
            "doc.tran.voluntary.debit.note": "Voluntary debit note",
            "doc.tran.compulsory.endorsement.note": "Compulsory endorsement note",
            "doc.tran.compulsory.credit.note": "Compulsory credit note",
            "doc.tran.compulsory.debit.note": "Compulsory debit note",
            "doc.tran.ew.invoice": "RECEIPT / TAX INVOICE",
            "doc.tran.ew.rsa": "Roadside Assistance Service Contract Schedule"
        },
        "th": {
            "document.label.will.generated.shortly":  "ระบบกำลังเตรียมเอกสารอิเล็กทรอนิกส์ และจะสามารถดาวน์โหลดได้ในเร็ว ๆ นี้",
            "doc.tran.compulsory.schedule": "ตารางกรมธรรม์ประกันรถภาคบังคับ (พ.ร.บ.)",
            "doc.tran.welcome.letter": "จดหมายต้อนรับ",
            "doc.tran.voluntary.schedule": "ตารางกรมธรรม์ประกันรถภาคสมัครใจ",
            "doc.tran.addendum": "บันทึกเพิ่มเติม",
            "doc.tran.voluntary.tax.invoice": "ใบกำกับภาษีประกันรถภาคสมัครใจ",
            "doc.tran.compulsory.tax.invoice": "ใบกำกับภาษีประกันรถภาคบังคับ (พ.ร.บ.)",
            "doc.tran.rsa.schedule": "สัญญาบริการช่วยเหลือฉุกเฉินบนท้องถนน",
            "doc.tran.rsa.tax.invoice": "ใบเสร็จรับเงิน / ใบกำกับภาษีบริการช่วยเหลือฉุกเฉินบนท้องถนน",
            "doc.tran.ci.addendum": "บันทึกเพิ่มเติมตรวจสภาพรถ",
            "doc.tran.tc.instalment": "ข้อกำหนดและเงื่อนไขการผ่อนชำระ",
            "doc.tran.voluntary.endorsement.note": "เอกสารแนบท้ายกรมธรรม์ประกันภัยภาคสมัครใจ",
            "doc.tran.voluntary.credit.note": "ใบลดหนี้ประกันภัยภาคสมัครใจ",
            "doc.tran.voluntary.debit.note": "ใบเพิ่มหนี้ประกันภัยภาคสมัครใจ",
            "doc.tran.compulsory.endorsement.note": "เอกสารแนบท้ายกรมธรรม์ประกันภัยภาคบังคับ (พ.ร.บ.)",
            "doc.tran.compulsory.credit.note": "ใบลดหนี้ประกันภัยภาคบังคับ (พ.ร.บ.)",
            "doc.tran.compulsory.debit.note": "เพิ่มหนี้ประกันภัยภาคบังคับ (พ.ร.บ.)",
            "doc.tran.ew.invoice": "ใบเสร็จรับเงิน / ใบกำกับภาษี",
            "doc.tran.ew.rsa": "ตารางสรุปสัญญาบริการช่วยเหลือบนท้องถนน"
        },
        "zh": {
            "document.label.will.generated.shortly":  "正在为您准备电子保单文件中。约2小时后，您可重新回到这里下载。",
            "doc.tran.compulsory.schedule": "Compulsory insurance policy schedule",
            "doc.tran.welcome.letter": "Welcome letter",
            "doc.tran.voluntary.schedule": "Voluntary insurance policy schedule",
            "doc.tran.addendum": "Addendum",
            "doc.tran.voluntary.tax.invoice": "Voluntary insurance tax invoice",
            "doc.tran.compulsory.tax.invoice": "Compulsory insurance tax invoice",
            "doc.tran.rsa.schedule": "Roadside assistance service contract schedule",
            "doc.tran.rsa.tax.invoice": "Roadside assistance service receipt/tax invoice",
            "doc.tran.ci.addendum": "Car inspection addendum",
            "doc.tran.tc.instalment": "Terms and conditions for instalment payment",
            "doc.tran.voluntary.endorsement.note": "Voluntary endorsement note",
            "doc.tran.voluntary.credit.note": "Voluntary credit note",
            "doc.tran.voluntary.debit.note": "Voluntary debit note",
            "doc.tran.compulsory.endorsement.note": "Compulsory endorsement note",
            "doc.tran.compulsory.credit.note": "Compulsory credit note",
            "doc.tran.compulsory.debit.note": "Compulsory debit note",
            "doc.tran.ew.invoice": "RECEIPT / TAX INVOICE",
            "doc.tran.ew.rsa": "Roadside Assistance Service Contract Schedule"
        },
    }
</i18n>
